<template>
  <popup :width="width" heightPopup="90%" :closePopupEvent="closePopupCB">
    <div slot="header">
      <h6 class="login-header">Signup with</h6>
    </div>
    <div class="session-exp-popup">
      <form-error v-show="formError" :message="formError"></form-error>
      <div>
        <div class="social-logo">
          <div class="login-div" id="facebooklogin" @click="signupFacebookCB">
            <img
              src="@/assets/icons/facebook_login.svg"
              class="login-img"
              alt="facebook logo"
              loading="lazy"
            />
          </div>
          <div id="google_login" class="login-div">
            <img
              src="@/assets/icons/google_login.svg"
              alt="google logo"
              class="login-img"
              @click="initGoogleSignupAPI"
              loading="lazy"
            />
          </div>
          <!-- <div id="apple_login" class="login-div">
            <img src="@/assets/icons/apple_login.svg" alt="apple logo" loading="lazy"/>
          </div>-->
        </div>
        <div class="vlt-signup-divd">
          <p class="or">or</p>
        </div>
        <div class="signup-form">
          <form @submit.prevent="signupCB" autocomplete="off">
            <div class="vlt-signup-form">
              <div class="vlt-input-group">
                <label>Name</label>
                <input
                  id="subscribername"
                  type="text"
                  v-model="subscribername"
                  placeholder="Enter your name"
                  ref="subscribername"
                  maxlength="40"
                />
                <p class="login-error-message">{{ subscribernameError }}</p>
              </div>

              <!-- Email Input -->
              <div class="vlt-input-group">
                <label>Email</label>
                <input
                  id="userEmail"
                  type="text"
                  v-model="userEmail"
                  placeholder="Enter email address"
                  maxlength="64"
                />
                <p class="login-error-message">{{ userEmailError }}</p>
              </div>

              <!-- Password Input -->
              <div class="vlt-input-group input-ico">
                <label>Password</label>
                <div class="vlt-input">
                  <input
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    v-model="password"
                    maxlength="16"
                  />
                  <span class="ico-img" style="cursor: pointer">
                    <img
                      :src="iconEyeOpenPath"
                      @click="toggleInputMode"
                      alt="eye img"
                      loading="lazy"
                    />
                  </span>
                </div>
                <p class="login-error-message">{{ passwordError }}</p>
              </div>
              <!-- Country -->
              <div class="vlt-input-group">
                <label>Country</label>
                <input
                  id="country"
                  type="text"
                  v-model="userCountry"
                  readonly
                />
              </div>
              <!-- Buttons -->
              <div class="vlt-btn signup-div">
                <button
                  type="submit"
                  class="btn-login"
                  ref="signUpBtnSubmit"
                  aria-label="Signup"
                >
                  Signup
                </button>
              </div>
            </div>
          </form>
        </div>
        <app-loader v-show="showLoading"></app-loader>
        <div class="vlt-signup">
          <p class="new-user">I already have an account</p>
          <button
            @click="openLoginPopup"
            class="login"
            ref="loginBtn"
            aria-label="Login"
          >
            Login
          </button>
        </div>
      </div>
    </div>
  </popup>
</template>

<script>
import { EventBus } from "@/main";
import { _googleId } from "@/module/vendor-config";
import appMixins from "@/mixins/appMixins";
import GoogleAnalytics from "@/mixins/GoogleAnalytics";

import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "SignupForm",
  data() {
    return {
      width: "30%",
      subscribername: "",
      userEmail: "",
      password: "",
      iconEyeOpenPath: require("@/assets/icons/eye_open.svg"),
      iconEyeClosePath: require("@/assets/icons/eye_closed.svg"),
      showLoading: false,
      formError: "",
      subscribernameError: "",
      userEmailError: "",
      passwordError: "",
      social: {},
      userCountry: "",
      facebookToken: "",
      signupErrorMessage:'Signup failed. Please try again.'
    };
  },
  props: ["closePopupCB", "previousActionData"],
  computed: {
    ...mapGetters([
      "appMessages",
      "appConfig",
      "currentContent",
      "detailCardStatus",
    ]),
  },
  watch: {
    formError(value) {
      if (value) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    },
    subscribername() {
      if (this.subscribernameError) {
        this.subscribernameError = null;
      }
    },
    userEmail() {
      if (this.userEmailError) {
        this.userEmailError = null;
      }
    },
    password() {
      if (this.passwordError) {
        this.passwordError = null;
      }
    },
  },
  beforeDestroy() {
    EventBus.$off("signupSuccess");
    EventBus.$off("signupError");
    EventBus.$off("fbPrompt-closed", this.closePopupCB);
  },
  methods: {
    ...mapMutations(["commitCurrentContent", "commitTicketResponse"]),
    ...mapActions(["actGetPlans", "actGetTicket"]),
    openLoginPopup() {
      EventBus.$emit("LoginPopup", this.previousActionData);
      this.closePopupCB();
    },
    getcountry() {
      if (localStorage.getItem("country")) {
        let countryCode = localStorage.getItem("country");
        import("../../../public/config/countryList.json").then((country) => {
          let countryList = country;
          for (var i in countryList) {
            if (countryList[i].code === countryCode) {
              this.userCountry = countryList[i].name;
            }
          }
        });
      }
    },
    signupCB() {
      if (!this.isFormValid()) return;
      document.getElementById("google_login").style.pointerEvents = "none";
      this.showLoading = true;
      this.$refs.signUpBtnSubmit.disabled = true;
      this.$refs.loginBtn.disabled = true;
      
      EventBus.$emit("registerUser", {
        subscribername: this.subscribername,
        email: this.userEmail,
        password: this.password,
      });
      
    },
    signupFacebookCB() {
      if (this.showLoading) return;
      let self = this;
      FB.login(
        function (response) {
          if (response.authResponse) {
            console.log("auth token", response.authResponse);

            let token = response.authResponse.accessToken;
            self.facebookToken = token;
            self.social = {
              app: "FB",
              token: token,
            };
            FB.api(
              "/me",
              "GET",
              { locale: "en_US", fields: "id,name,email" },
              function (response) {
                if (response) {
                  self.showLoader();
                  
                  EventBus.$emit("loginUser", self.social, "S");
                  
                }
              }
            );
          }
        },
        { scope: "public_profile,email" }
      );
    },
    initGoogleSignupAPI() {
      gapi.load("auth2", () => {
        let auth2 = gapi.auth2.init({
          client_id: _googleId,
          cookiepolicy: "single_host_origin",
          scope: "profile",
        });

        auth2.attachClickHandler(
          document.getElementById("google_login"),
          {},
          (user) => {
            var profile = user.getBasicProfile();
            if (profile.getId()) {
              let token = user.getAuthResponse().id_token;
              this.social = {
                app: "GOOGLE",
                token: token,
              };
              this.showLoader();
              
              EventBus.$emit("loginUser", this.social, "S");
              
            }
          },
          (error) => {}
        );
      });
    },
    // initAppleLogin() {
    //   AppleID.auth.init({
    //     clientId: "com.mobiotics.web.vLite",
    //     scope: "email",
    //     redirectURI: "https://vlite-ent.firebaseapp.com/",
    //     state: "qvlmvpyqBmyNx0x7MvBJ3DG7obdbUZ",
    //     usePopup: true //or false defaults to false
    //   });
    //   const buttonElement = document.getElementById("apple_login");
    //   buttonElement.addEventListener("click", () => {
    //     const data = AppleID.auth.signIn().then(
    //       response => {
    //         // if (response.authorization.id_token) {
    //         if (
    //           response &&
    //           response.authorization &&
    //           response.authorization.id_token
    //         ) {
    //           let token = response.authorization.id_token;
    //           this.social = {
    //             app: "APPLE",
    //             token: token
    //           };
    //           this.showLoader();
    //           EventBus.$emit("loginUser", this.social, "S");
    //         }
    //       },
    //       err => {
    //         console.log("error", err);
    //       }
    //     );
    //   });
    //   /*document.addEventListener('AppleIDSignInOnSuccess', (data) => {
    //     //handle successful response
    //     console.log("data...", data);
    //   });
    //   document.addEventListener("AppleIDSignInOnFailure", error => {
    //     //handle error.
    //       console.log('error', error);
    //   });*/
    // },
    isFormValid() {
      return (
        this.validateName() &&
        this.validateEmailInput() &&
        this.validatePassword()
      );
    },
    validateName() {
      let maxChar = 64;
      if (!this.subscribername) {
        this.subscribernameError =
          this.appMessages && this.appMessages.emptyFieldName
            ? this.appMessages.emptyFieldName
            : "Please enter Name";
        return false;
      } else if (!this.validateInputName(this.subscribername)) {
        this.subscribernameError =
          this.appMessages && this.appMessages.invalidName
            ? this.appMessages.invalidName
            : "Enter valid Name";
        return false;
      } else if (this.subscribername.length > maxChar) {
        this.subscribernameError = `Name should be less than ${maxChar} characters`;
        return false;
      } else {
        this.subscribernameError = "";
        return true;
      }
    },
    validateEmailInput() {
      let maxChar = 128;
      if (!this.userEmail) {
        this.userEmailError =
          this.appMessages && this.appMessages.emptyFieldEmail
            ? this.appMessages.emptyFieldEmail
            : "Please enter Email ID";
        return false;
      } else if (!this.validateEmail(this.userEmail)) {
        this.userEmailError =
          this.appMessages && this.appMessages.invalidEmail
            ? this.appMessages.invalidEmail
            : "The email id you entered is invalid";
        return false;
      } else if (this.userEmail.length > maxChar) {
        this.userEmailError = `E-mail must be less than ${maxChar} characters`;
        return false;
      } else {
        this.userEmailError = "";
        return true;
      }
    },
    validatePassword() {
      let minChar = 8;
      let maxChar = 16;

      if (!this.password) {
        this.passwordError =
          this.appMessages && this.appMessages.emptyFieldPassword
            ? this.appMessages.emptyFieldPassword
            : "Please enter Password";
        return false;
      } else if (
        this.password.length < minChar ||
        this.password.length > maxChar
      ) {
        this.passwordError =
          this.appMessages && this.appMessages.invalidPassword
            ? this.appMessages.invalidPassword
            : `Password should be ${minChar} - ${maxChar} characters long`;
        return false;
      } else {
        this.passwordError = "";
        return true;
      }
    },
    toggleInputMode(_event) {
      let target = _event.target;
      let parentNode = target.parentNode.parentNode;
      let inputElm = parentNode.getElementsByTagName("input")[0];
      if (inputElm.type === "password") {
        inputElm.type = "text";
        target.src = this.iconEyeClosePath;
      } else {
        inputElm.type = "password";
        target.src = this.iconEyeOpenPath;
      }
    },
    showLoader() {
      this.showLoading = true;
      this.$refs.loginBtn.disabled = true;
      this.$refs.signUpBtnSubmit.disabled = true;
    },
    signUpAnalytics(data) {
      let Analytics = {
        Name: this.subscribername,
        Email: this.userEmail,
      };
      let loginType = JSON.parse(localStorage.getItem("loginType"));
      Analytics.Mode = loginType.type;
      if (data.label === "Failure") {
        Analytics["Event Label"] = data.label;
        Analytics.Status = data.Status;
        Analytics.ErrorCode = data.Code;
        Analytics.ErrorMessage = data.Message;
      }

      if (data.label === "Success") {
        Analytics["Event Label"] = data.label;
        Analytics.Status = data.Status;
      }

      this.signUpEvent(Analytics);
    },
    navigateTo() {
      if (this.subscriberId) {
        let data = {};
        data.subscriberid = this.subscriberId;

        let subscriberPromise = this.actGetPlans(data).then((response) => {
          if (
            (response.error && response.error === 9104) ||
            (response.planstatus &&
              response.planstatus !== "ACTIVE" &&
              response.planstatus !== "TRIAL" &&
              response.planstatus !== "CANCEL")
          )
            return true;
          else return false;
        });

        let ticketPromise = this.actGetTicket()
          .then((response) => {
            if (!response.error) {
              this.commitTicketResponse(response);
              return response;
            } else return false;
          })
          .catch((error) => {
            console.log("error ticket ", error);
            if (error.status === 401) {
              // this.unauthorisedHandler();
              if (this.flagAutologin) {
                this.commonAutoLogin(this.navigateTo);
                this.flagAutologin = false;
                return;
              }
            }
          });

        if (
          this.appConfig.featureEnabled.subscription &&
          this.appConfig.featureEnabled.purchase
        ) {
          Promise.all([subscriberPromise, ticketPromise]).then((res) => {
            if (!res[0]) return;
            if (
              this.currentContent &&
              res[1] &&
              res[1].some(
                (content) => content.contentid === this.currentContent.contentid
              )
            )
              return;

            if (this.currentContent)
              this.emitPayment({
                intermediate: true,
                menuClicked: true,
              });
            else
              this.emitPayment({
                subscription: true,
                menuClicked: true,
              });
          });
        }
        if (
          this.appConfig.featureEnabled.subscription &&
          !this.appConfig.featureEnabled.purchase
        ) {
          subscriberPromise.then((response) => {
            if (response) {
              this.emitPayment({
                subscription: true,
                menuClicked: true,
              });
            }
          });
        }
        if (
          this.appConfig.featureEnabled.purchase &&
          !this.appConfig.featureEnabled.subscription
        ) {
          ticketPromise.then((response) => {
            if (
              response &&
              this.currentContent &&
              response.some(
                (content) => content.contentid === this.currentContent.contentid
              )
            )
              return;
            if (!this.currentContent) return;
            else
              this.emitPayment({
                purchase: true,
                menuClicked: true,
              });
          });
        }
      }
    },
    emitPayment(data) {
      if ( this.currentContent ){
        if( this.currentContent.context__ ) data = this.currentContent.context__ ;
        delete this.currentContent.context__ ;
        data.content = this.currentContent;
      } 
      this.commitCurrentContent(null);
       data.autoAction=true;
      EventBus.$emit("paymentInitPopup", data);
    },
    checkDeepLink() {
      if (this.currentContent) {
        EventBus.$emit("opendeeplink");
      }
    },

    checkDeepLink() {
      if (this.currentContent) {
        EventBus.$emit("opendeeplink");
      }
    },
  },
  mounted() {
    window.loadGoogleApi &&
      window.loadGoogleApi().then(() => {
        this.initGoogleSignupAPI();
      });
    // this.initAppleLogin();
  },
  created() {
    this.getcountry();
    EventBus.$on("fbPrompt-closed", this.closePopupCB);
    EventBus.$on("signupError", (error) => {
      let signupData = {
        label: "Failure",
        Status: "FAILURE",
        Code: error.error ? error.error : "",
        Message: error.reason,
      };

      this.signUpAnalytics(signupData);

      this.showLoading = false;

      this.formError = error.reason || error.Message ||this.signupErrorMessage;
      this.$refs.signUpBtnSubmit.disabled = false;
      this.$refs.loginBtn.disabled = false;
      this.showLoading = false;
      document.getElementById("google_login").style.pointerEvents = "auto";
    });
    EventBus.$on("signupSuccess", () => {
      if (this.detailCardStatus && this.detailCardStatus.content) {
        // EventBus.$emit("openDetailCard",{detailCard: this.detailCardStatus.content});
        this.routeToCardView(this.detailCardStatus.content.contentid);
      } else if (!this.currentContent) this.navigateTo();
      else if (
        this.currentContent.price &&
        Array.isArray(this.currentContent.price)
      ) {
        if (
          this.currentContent.price.some(
            (element) => parseInt(element.amount) === 0
          )
        ) {
          this.checkDeepLink();
        } else this.navigateTo();
      } else {
        this.checkDeepLink();
      }
      let signupData = {
        label: "Success",
        Status: "SUCCESS",
      };

      this.signUpAnalytics(signupData);

      console.log("signup success");
      if (this.facebookToken) {
        let obj = {};
        obj.popupDisplay = false;
        EventBus.$emit("facebookLogin", obj);
      }
      this.showLoading = false;
      document.getElementById("google_login").style.pointerEvents = "auto";
      this.closePopupCB();
    });
    EventBus.$on("loginError", (error) => {
      console.log("loginError", error);

      let GA = {
        "Event Label": "Failure",
        Status: "FAILURE",
        ErrorCode: error.Code ? error.Code : "",
        ErrorMessage: error.Message,
      };
      this.loginEvent(GA);

      this.showLoading = false;
      if (error.Code === 9101) {
        let obj = {};
        obj.facebookToken = this.facebookToken;
        obj.popupDisplay = true;
        obj.page = "S";
        EventBus.$emit("facebookLogin", obj);
        return;
      }
      this.formError = error.Message;
      this.$refs.loginBtnSubmit.disabled = false;
      this.$refs.signupBtn.disabled = false;
      document.getElementById("googlelogin").style.pointerEvents = "auto";
    });
  },
  components: {
    popup: () =>
      import(/* webpackChunkName: "Popup" */ "@/components/Shared/popup.vue"),
    "form-error": () =>
      import(
        /* webpackChunkName: "formError" */ "@/components/Shared/templates/formError.vue"
      ),
    "app-loader": () =>
      import(
        /* webpackChunkName: "appLoader" */ "@/components/Shared/templates/appLoader.vue"
      ),
  },
  mixins: [appMixins, GoogleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";
@import "~sass/modules/_hovereffects.scss";
@import "~sass/modules/_hovereffects.scss";

input::-ms-clear,
input::-ms-reveal {
  display: none;
}
input:invalid {
  outline: none;
}

input[type="text"],
input[type="search"],
input[type="password"],
input[type="email"],
textarea {
  font-size: 18px;
  font-weight: $font-weight-medium;
}
.session-exp-popup {
  padding: 5px 20px;
  @include breakpoint(max600) {
    padding: 10px;
  }
}
.login-header {
  color: $font-clr-white;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  font-size: 22px;
  @include breakpoint(max600) {
    font-size: 20px;
  }
  // line-height: 37px;
}

.login-div {
  height: 100%;
  cursor: pointer;
}
.login-img {
  @include headerIconsHover;
}
.social-logo {
  margin: auto;
  width: 50%;
  display: flex;
  justify-content: space-around;
  div {
    // flex: 1;
    div {
      cursor: pointer;
      img {
        margin: 10px;
        width: 15%;
        float: left;
      }
      p {
        float: left;
        font-family: $font-family;
        font-weight: $font-weight-medium;
        margin-left: 5px;
      }
    }
  }
}
.vlt-signup-divd {
  p {
    display: block;
    margin: 15px 0;
    color: $font-clr-gray;
    font-weight: 550;
    font-size: 15px;
    text-align: center;
    font-family: $font-family;
    font-weight: $font-weight-medium;

    @include breakpoint(max600) {
      margin: 7px 0;
      font-size: 16px;
    }
  }
  .or {
    margin: 5px 0;
  }
}

.or {
  color: $font-clr-gray;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 19px;
  // font-weight: 500;
  line-height: 19px;
  text-align: justify;
  opacity: 1;
}
.signup-form {
  margin: 0 10% 0 10%;
}
.vlt-input-group {
  margin: 0 0 10px 0;
  input {
    @include inputFocus;
  }
  @include breakpoint(max600) {
    margin: 0 0 5px 0;
    label {
      font-size: 14px;
    }
  }
}
.vlt-btn {
  background: none;
}
.signup-div {
  margin-top: 5%;
}
.vlt-signup {
  padding: 10px 20px;

  :nth-child(1) {
    margin-left: 15%;
  }
  :nth-child(2) {
    margin-left: 10px;
    cursor: pointer;
    font-weight: 600;
  }
  p {
    color: $font-clr-gray;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    float: left;
  }
}
::placeholder {
  color: $font-clr-white-1;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 13px;
}
.new-user {
  color: $font-clr-gray;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 13px;
  line-height: 18px;
}
.login {
  color: $theme-primary !important;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 18px;
  border: none;
  background: transparent;
  &:hover {
    text-decoration: underline;
  }
}

@include breakpoint(max600) {
  input[type="email"],
  input[type="password"],
  input[type="text"] {
    height: 30px;
  }
  button {
    padding: 8px;
    font-size: 15px;
  }
  .vlt-signup {
    padding: 10px;
    text-align: center;

    :nth-child(1) {
      margin-left: 0%;
    }
    :nth-child(2) {
      margin-left: 0%;
    }
    p {
      display: inline;
      text-align: center;
      float: none;
    }
  }
}
/* Extra small devices (phones, 600px and down) */
// @media only screen and (max-width: 600px) {
//   .session-exp-popup {
//     padding: 10px;
//   }
//   .vlt-input-group {
//     margin: 0 0 5px 0;
//     label {
//       font-size: 14px;
//     }
//   }
//   .login-header {
//     font-size: 20px;
//   }
//   .vlt-signup-divd {
//     p {
//       margin: 7px 0;
//       font-size: 16px;
//     }
//   }
//   input[type="email"],
//   input[type="password"],
//   input[type="text"] {
//     height: 30px;
//   }
//   button {
//     padding: 8px;
//     font-size: 15px;
//   }
//   .vlt-signup {
//     padding: 10px;
//     text-align: center;

//     :nth-child(1) {
//       margin-left: 0%;
//     }
//     :nth-child(2) {
//       margin-left: 0%;
//     }
//     p {
//       display: inline;
//       text-align: center;
//       float: none;
//     }
//   }
// }
// /* Small devices (portrait tablets and large phones, 600px and up) */
// @media only screen and (min-width: 600px) {
// }

// /* Medium devices (landscape tablets, 768px and up) */
// @media only screen and (min-width: 768px) {
// }

// /* Large devices (laptops/desktops, 992px and up) */
// @media only screen and (min-width: 992px) {
// }

// /* Extra large devices (large laptops and desktops, 1200px and up) */
// @media only screen and (min-width: 1200px) {
// }
</style>
